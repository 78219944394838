import { flare } from "@flareapp/flare-client";

flare.beforeSubmit = (report) => {

    // Filter out errors that are not useful
    if ([
        "Request failed with status code 401",
        "Network Error",
        "Failed to fetch dynamically imported module",
        "is not a valid JavaScript MIME type",
        "Unable to preload CSS"
    ].some(v => report.message.includes(v))) {
        return false;
    }

    // Filter silly bots
    if ([
        "adsbot",
        "googlebot"
    ].some(v => report.context.request.useragent.includes(v))) {
        return false;
    }

    return report;
};

export default flare;
